import { Swiper } from "swiper/react";
import {
  Navigation,
  EffectCoverflow,
  Scrollbar,
  A11y,
  Virtual,
  Autoplay,
} from "swiper/modules";
import { ReactNode } from "react";

const SwiperCard = ({
  ElementList,
  className,
  breakpoints,
}: {
  ElementList: ReactNode;
  className?: string;
  breakpoints?: any;
}) => {
  return (
    <div className={`flex flex-row justify-center w-full ${className}`}>
      <Swiper
        effect="fade"
        id={"swiper-logo"}
        modules={[
          EffectCoverflow,
          Navigation,
          Scrollbar,
          A11y,
          Autoplay,
          Virtual,
        ]}
        speed={10000}
        loop={true}
        breakpoints={
          breakpoints || {
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            464: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }
        }
        autoplay={{
          delay: 0,
          reverseDirection: false,
          pauseOnMouseEnter: true,
        }}
      >
        <div className="my-5">{ElementList}</div>
      </Swiper>
    </div>
  );
};

export default SwiperCard;
