import { useState } from "react";
import {
  getherGoLogo,
  message,
  qrLine,
  questionIcon,
  rightArrow,
  tel,
} from "../assets/img";
import Navbar from "../layout/Navbar";
import { layoutStyle } from "./Home";
import {
  CustomInput,
  CustomInputPhone,
  CustomTextArea,
} from "../components/field/Field";
import toast from "react-hot-toast";
import { postContactUs } from "../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { CONTACTINFO } from "../constants/contactUs";
import { Mailto, PhoneCall } from "../components/Link";

const ContactUs = () => {
  const [formData, setFormData] = useState<any>({
    fullName: "",
    phone: "",
    message: "",
    email: "",
    select: "",
  });

  // const [recaptcha, setRecaptcha] = useState("");

  const handleInput = (e: any) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    console.log("fieldValue", fieldValue);
    setFormData((prevState: any) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmitForm = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    try {
      if (
        formData.email &&
        formData.fullName &&
        formData.phone &&
        // formData.select &&
        isValidEmail(formData.email)
      ) {
        const res = await toast.promise(postContactUs(formData), {
          loading: "Loading . . .",
          success: "Send messages success",
          error: "Oops, Something went wrong",
        });
        if (res) {
          setFormData({
            fullName: "",
            phone: "",
            email: "",
            select: "",
            message: "",
          });
        }
      } else toast.error("Please fill all field");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#17233F] to-[#091022] relative">
      <Navbar />
      <div className="text-5xl text-white text-center font-semibold pt-[4rem] pb-[3rem]">
        Contact Us
      </div>
      <div className={`bg-[#f4f8fb] flex justify-center ${layoutStyle}`}>
        <div className="grid grid-cols-2 mobile:grid-cols-1 gap-4">
          <div
            className="rounded-lg shadow-md pt-[2rem] pb-[1rem] bg-white px-[2rem]
          tablet:px-[1rem] mobile:px-[1rem]"
          >
            <p className="text-2xl font-bold text-center text-[#1DBEC8]">
              Send a message
            </p>
            <p className="text-[#666666] text-sm text-center my-3 w-full">
              If you would like to contact us, please provide your
              <br /> personal details and details of your request.
            </p>
            <form
              onSubmit={handleSubmitForm}
              className="flex flex-col justify-center"
            >
              <div className="my-4">
                <CustomInput
                  name={"fullName"}
                  label={"Full Name"}
                  placeholder="Name"
                  onChange={handleInput}
                  value={formData?.fullName}
                />
              </div>

              <div className="mb-4">
                <CustomInputPhone
                  name={"phone"}
                  label={"Phone"}
                  value={formData?.phone}
                  pattern="[0-9]{10}"
                  maxLength={10}
                  placeholder="xxxxx xxxxx"
                  onChange={handleInput}
                />
              </div>

              <div className="mb-4">
                <CustomInput
                  type="email"
                  name={"email"}
                  label={"Email Address"}
                  placeholder="Email Address"
                  onChange={handleInput}
                  value={formData?.email}
                />
              </div>

              {/* <div className="mb-4">
                <CustomSelect
                  name="select"
                  label="Request Quote"
                  options={[
                    {
                      label: "Option 1",
                      value: "opt1",
                    },
                    {
                      label: "Option 2",
                      value: "opt2",
                    },
                    {
                      label: "Option 3",
                      value: "opt3",
                    },
                  ]}
                  placeHolder="select"
                  onChange={({
                    name,
                    value,
                  }: {
                    name: string;
                    value: string;
                  }) =>
                    setFormData((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  align={"right"}
                />
              </div> */}

              <div className="mb-4">
                <CustomTextArea
                  name={"message"}
                  label={"Message"}
                  placeholder="Message"
                  onChange={handleInput}
                  maxLength={500}
                  rows={3}
                  value={formData?.message}
                />
                <div className="py-2 text-[#646B7D] text-sm">
                  Characters remaining: {500 - Number(formData.message?.length)}
                </div>
              </div>

              <div className="w-full flex justify-center mb-8 tablet:mb-[3rem]">
                <ReCAPTCHA
                  sitekey="6LfcnNcpAAAAAOEID-8bJpRzSnu3NEra_HSHe4yI"
                  onChange={(value) => console.log("", value)}
                />
              </div>

              <div className="w-full flex flex-row justify-center tablet:pb-[1.5rem]">
                <button
                  type="submit"
                  className="w-full py-3 rounded-[8px] bg-[#F79552] text-white text-center font-semibold text-[16px]"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
          {/* ---------------------------------- left ----------------------------------- */}
          <div>
            <div className="rounded-lg shadow-md p-[2rem] bg-white mb-[1rem] text-center w-full">
              <div className="flex justify-center my-[1rem]">
                <img
                  src={getherGoLogo}
                  alt="logo-gotech"
                  className="w-[7rem] h-auto"
                />
              </div>
              <p className="text-[#404040] text-xl font-bold w-full mt-[1.5rem]">
                The gethergo Co., Ltd
              </p>
              <p className="text-[#666666] text-sm mt-3 w-full">
                18/5 Nirun Avenue Village, Soi Ramkhamhaeng <br />
                174, Min Buri Subdistrict, Min Buri District,
                <br /> Bangkok, 10510
              </p>
            </div>
            {/* --------------------------------right 1------------------------------------- */}

            <div className="rounded-lg shadow-md p-[2rem] bg-white mb-[1rem]">
              <div className="grid gap-10 grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1">
                <div>
                  <div className="flex justify-center mb-2">
                    <img src={message} alt={message} className="w-7" />
                  </div>
                  <p className="text-lg font-bold text-center">
                    <Mailto email={CONTACTINFO?.EMAIL}>
                      {CONTACTINFO?.EMAIL}
                    </Mailto>
                  </p>
                </div>
                <div>
                  <div className="flex justify-center mb-2">
                    <img src={tel} alt={tel} className="w-7" />
                  </div>
                  <p className="text-lg font-bold text-center">
                    <PhoneCall tel={CONTACTINFO?.PHONE}>
                      (+66) {CONTACTINFO?.PHONE}
                    </PhoneCall>
                  </p>
                </div>
              </div>
            </div>
            {/* --------------------------------right 2------------------------------------- */}

            <div className="rounded-lg shadow-md p-[2rem] bg-white mb-[1rem]">
              <div className="flex justify-center mb-2">
                <img src={qrLine} alt={qrLine} className="min-w-40" />
              </div>
              <p className="text-lg font-bold text-center">@gethergo</p>
            </div>
            {/* --------------------------------right 3------------------------------------- */}
            <div className="rounded-lg shadow-md py-[1.5rem] px-[2rem] bg-white w-full">
              <div className="flex">
                <div className="flex justify-center items-center pr-[1.5rem]">
                  <img
                    src={questionIcon}
                    alt={questionIcon}
                    className="w-16 h-16 tablet:h-auto mobile:h-auto"
                  />
                </div>
                <div>
                  <p className="text-xl font-bold mb-3">Help center</p>
                  <p className="text-[#666666] text-sm mb-3">
                    Includes frequently asked questions and provides <br />
                    assistance to you regarding the use of our system.
                  </p>
                  <p
                    className="text-sm text-[#1DBEC8] flex items-center font-semibold
                  cursor-pointer hover:underline hover:underline-offset-4 w-fit"
                    onClick={() =>
                      window.open("http://line.me/ti/p/~@gethergo")
                    }
                  >
                    See more
                    <img
                      src={rightArrow}
                      alt={rightArrow}
                      className="w-4 h-4 ml-1"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------------------right ------------------------------------- */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};
