import { useLocation, useNavigate } from "react-router-dom";
import { gotechLogoBlack, gotechLogoWhite } from "../assets/img";
import { ButtontLine, MainButton } from "../components/Buttont";
import { useEffect, useState } from "react";

export const ScrollTo = async (id: string = "") => {
  let scrollDiv = document?.getElementById(id)?.offsetTop;
  window.scrollTo({ top: scrollDiv, behavior: "smooth" });
};

const Navbar = ({ onSticky }: { onSticky?: (value: boolean) => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [activeNav, setActiveNav] = useState(
    location?.pathname === "/contact-us"
      ? ""
      : location?.state?.activeNav || "Overview"
  );

  const HandleHomePage = async (nav: string) =>
    navigate("/", { state: { activeNav: nav } });

  const ActiveNavMenu = async (nav: string) => setActiveNav(nav);

  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (windowHeight > 50) {
        setStickyClass("fixed top-0 w-full bg-[white] pb-[1rem] shadow-md");
        onSticky && onSticky(true);
      } else {
        setStickyClass("");
        onSticky && onSticky(false);
      }
    }
  };

  return (
    <div
      className={`desktopL:px-[10vw] px-[6rem] tabletL:px-[4rem] tablet:px-[4rem] mobile:px-[1.5rem]
      desktop:pt-[1.5rem] pt-[1rem] mobile:pt-[1.5rem] transition duration-300 ease-in-out
     flex justify-between items-center z-10 ${stickyClass}`}
    >
      <img
        src={stickyClass ? gotechLogoBlack : gotechLogoWhite}
        alt="logo-gotech"
        className="w-[7rem] h-auto desktopL:pt-0 desktopM:pt-0 pt-2 mobile:pt-[0rem] cursor-pointer"
        onClick={() => HandleHomePage("Overview")}
      />
      <TapsTypeList
        listType={listTaps}
        onClick={async (nav) => {
          if (location?.pathname === "/contact-us") {
            await HandleHomePage(nav);
          }
          await ScrollTo(nav);
          await ActiveNavMenu(nav);
        }}
        activetap={activeNav}
        className={`desktopM:flex tablet:hidden mobile:hidden`}
        classItem={`${stickyClass ? "!text-[black]" : ""}`}
      />
      <div className="items-center justify-center desktop:flex tablet:hidden mobile:flex">
        <MainButton
          text={"Contact Us"}
          onClick={() => navigate("/contact-us")}
          className="mr-[1rem] mobile:mr-0 mobile:h-[2.5rem] mobile:w-fit mobile:px-[1rem]"
        />
        <ButtontLine
          text={"Request Quote"}
          className="mobile:hidden border-none"
          onClick={() => window.open("http://line.me/ti/p/~@gethergo")}
        />
      </div>
      {/* ---------------------------- mobile -------------------------------- */}
      <span
        onClick={() => setOpen(true)}
        className={`desktop:hidden tablet:block mobile:hidden ${
          stickyClass ? "text-black" : "text-white"
        } text-[1.7rem]`}
      >
        &#9776;
      </span>
      <div
        id="mySidenav"
        className={`sidenav ${
          open ? `tablet:w-[23rem] mobile:w-[19rem]` : "w-0"
        }`}
      >
        <div className="closebtn text-white" onClick={() => setOpen(false)}>
          &times;
        </div>
        <div className="px-[2rem] mobile:px-0">
          <TapsTypeList
            listType={listTaps}
            onClick={async (nav) => {
              if (location?.pathname === "/contact-us") {
                await HandleHomePage(nav);
              }
              await ScrollTo(nav);
              await ActiveNavMenu(nav);
            }}
            activetap={activeNav}
            className="overflow-hidden"
          />
          <div className="items-center justify-center px-[2rem]">
            <MainButton
              text={"Contact Us"}
              onClick={() => navigate("/contact-us")}
              className="mr-[1rem] w-[15rem] mt-[1rem] mb-[2rem]"
            />
            <ButtontLine
              text={"Request Quote"}
              onClick={() => window.open("http://line.me/ti/p/~@gethergo")}
              className="w-[15rem] border-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

export const TapsTypeList = ({
  listType,
  onClick,
  // activetap,
  className,
  classItem,
}: {
  listType: string[];
  onClick?: (tab: string) => void;
  activetap: string;
  className?: string;
  classItem?: string;
}) => {
  return listType?.length > 0 ? (
    <div className={`relative overflow-auto scroll-element mx-5 ${className}`}>
      <div className="flex desktop:flex-row flex-col w-full desktop:items-center items-start">
        {listType?.map((tabName: string, idx: number) => {
          return (
            <div
              key={idx}
              className={`px-4 py-2 mx-2 text-center font-semibold text-lg cursor-pointer
                 transition duration-300 ease-in-out text-white
                  whitespace-nowrap tab-text hover:text-white ${classItem}`}
              onClick={() => {
                onClick && onClick(tabName);
              }}
            >
              {tabName}
              <hr
                className={`bg-[#F79552] h-[0.17rem] mt-1 line-tab-text`}
                // ${activetap === tabName ? "opacity-100" : "opacity-0"}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export const listTaps = [
  "Overview",
  "Solutions & Services",
  "Case Studies",
  "Client",
];
