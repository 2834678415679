import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectCoverflow,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import {
  docsIcon,
  doubleQuart,
  fluentChat,
  humenIcon,
  lg1,
  lg2,
  lg3,
  lg4,
  lg5,
  lg6,
  lg7,
  lg8,
  peopleIcon,
  phone,
} from "../../assets/img";
import { layoutStyle } from "../Home";
import { CONTACTINFO } from "../../constants/contactUs";
import { Mailto, PhoneCall } from "../../components/Link";

const SectionFour = () => {
  return (
    <div className="pt-[5rem] mobile:pt-[0rem]" id="Client">
      <LogoSwiper
        data={[
          lg1,
          lg2,
          lg3,
          lg4,
          lg5,
          lg6,
          lg7,
          lg8,
          lg1,
          lg2,
          lg3,
          lg4,
          lg5,
          lg6,
          lg7,
          lg8,
        ]}
        breakpoint={breakpointsListTop}
        delay={10000}
      />
      {/* ----------------------------------------------------------------------- */}
      <div className={`bg-[#F5F7FA] mt-[5rem] ${layoutStyle} mobile:py-[3rem]`}>
        <div className="flex mobile:grid justify-center items-center">
          <div className="desktop:w-auto tablet:w-full mobile:w-full flex justify-center mobile:justify-start mb-0 mobile:mb-[2.5rem]">
            <img
              src={doubleQuart}
              alt="doubleQuart"
              className="w-[5.5rem] h-[5.5rem] mobile:w-[3rem] mobile:h-[3rem] tabletM:w-[4.5rem] tabletM:h-[4.5rem]"
            />
          </div>
          <div className="px-[4rem] tabletM:px-[2rem] tabletL:px-[0rem] mobile:px-[0rem] desktop:w-[55%]">
            <p className="font-bold text-4xl mobile:text-xl mb-[1.5rem] mobile:mb-[1rem]">
              About us
            </p>
            <p className="text-xl mobile:text-base">
              20 years of experience in multinational company with an expertise
              in supply chain strategic & management and business system analyst
              together with IT solution innovation & expert as well as in dept
              in project management definitely provide the solutions and fulfill
              all your requirements.
            </p>
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      <div className={`bg-[#17233F] ${layoutStyle} mobile:py-[5rem]`}>
        <div className="flex mobile:grid justify-center items-center">
          <div
            className="desktopM:mr-[10rem] desktopM:w-[35rem] 
          tablet:w-[25rem] tablet:mr-[3rem] mobile:mr-0"
          >
            <p
              className="text-4xl mobile:text-3xl 
            text-white font-semibold mb-[2rem]"
            >
              Start with 3 easy steps
            </p>
            {listStep?.map((item, idx: number) => {
              return (
                <CardStepItem
                  key={idx}
                  text={item?.text}
                  icon={item.icon}
                  index={idx + 1}
                />
              );
            })}
          </div>
          <div className="text-center text-white tabletM:ml-0 tabletL:ml-16 desktopL:ml-[8rem] desktopM:ml-[0rem] mt-0 mobile:mt-[2rem]">
            <div className="flex justify-center mb-[2.5rem] mobile:mb-[2rem]">
              <img src={phone} alt="phone" className="mobile:w-[2rem]" />
            </div>
            <p className="text-sm font-semibold mobile:font-medium tracking-widest mobile:tracking-[0.3rem]">
              {`reach out now!`.toUpperCase()}
            </p>
            <p className="desktop:text-5xl tabletL:text-3xl tabletM:text-2xl mobile:text-2xl font-semibold pt-[1.5rem] pb-[2.5rem] mobile:pb-[1.5rem]">
              <PhoneCall tel={CONTACTINFO?.PHONE}>
                {CONTACTINFO?.PHONE}
              </PhoneCall>
            </p>
            <p className="text-xl flex justify-center items-center">
              <img
                src={fluentChat}
                alt="fluentChat"
                className="mr-3 
              desktopL:w-[2rem] desktopL:h-[2rem]
              desktop:w-[1.5rem] desktop:h-[1.5rem]
              tablet:w-[1.5rem] tablet:h-[1.5rem]
              mobile:w-[1.5rem] mobile:h-[1.5rem]"
              />
              <Mailto email={CONTACTINFO?.EMAIL}>{CONTACTINFO?.EMAIL}</Mailto>
            </p>
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
    </div>
  );
};

export default SectionFour;

export const listStep = [
  { text: "Get requirements & Provide solutions", icon: peopleIcon },
  { text: "Estimate Project Cost & Quotation", icon: docsIcon },
  { text: "Kick off Project", icon: humenIcon },
];

export const CardStepItem = ({ text = "", icon = "", index = 1 }) => {
  return (
    <div className="flex items-center mobile:w-full">
      <div
        className="rounded-full bg-gradient-to-b from-[#F7C952] to-[#F79552] min-h-[1rem] min-w-[1rem] mr-[1rem]
      flex justify-center items-center px-[1.2rem] py-[0.6rem] text-2xl mobile:text-base text-white font-bold"
      >
        {index}
      </div>
      <div
        className="rounded-2xl my-[1rem] flex items-center bg-white font-semibold w-full
      px-[1.5rem] py-[1.5rem] mobile:py-[1rem] text-xl mobile:text-base"
      >
        <img
          src={icon}
          alt="phone"
          className="mr-3 mobile:w-[1.5rem] mobile:h-[1.5rem]"
        />
        <div className="w-full flex">{text}</div>
      </div>
    </div>
  );
};

export const breakpointsListTop = {
  0: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  464: {
    slidesPerView: 5,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 8,
    spaceBetween: 15,
  },
  1280: {
    slidesPerView: 8,
    spaceBetween: 20,
  },
};

export const LogoSwiper = ({
  data,
  breakpoint,
  delay,
}: {
  data: any[];
  breakpoint: any;
  delay: number;
}) => {
  return (
    <div className="flex flex-row justify-center w-full max-h-80">
      <Swiper
        effect="fade"
        id={"swiper-logo"}
        modules={[
          EffectCoverflow,
          Navigation,
          Scrollbar,
          A11y,
          Autoplay,
          Virtual,
        ]}
        speed={delay}
        loop={true}
        breakpoints={breakpoint}
        autoplay={{
          delay: 0,
          reverseDirection: false,
          pauseOnMouseEnter: true,
        }}
      >
        {data?.map((icon: string, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <div className="flex justify-center">
                <img
                  src={icon}
                  alt={icon}
                  className="w-[12rem] mobile:w-[9rem]
                  scale-100 transition duration-700 ease-in-out	hover:scale-[1.1]"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
