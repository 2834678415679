import { cas1, cas2, cas3 } from "../../assets/img";
import { layoutStyle } from "../Home";
import { SwiperSlide } from "swiper/react";
import SwiperCard from "../../components/SwiperCard";
import { CardContent } from "../../components/CardOverlay";

const SectionThree = () => {
  // const [activeMenu, setActiveMenu] = useState("Application Services");

  return (
    <div className={`${layoutStyle}`} id="Case Studies">
      <div className="text-center font-bold text-5xl tablet:text-4xl mobile:text-xl mb-[5rem] mobile:mb-[2rem]">Case studies</div>
      {/* <div className="flex justify-center">
        <TapsTypeList
          listType={listTaps}
          onClick={setActiveMenu}
          activetap={activeMenu}
        />
      </div> */}
      {/* -------------------------------------------- desktop ------------------------------------------- */}
      <div
        className="grid tablet:hidden grid-cols-3 mobile:grid-cols-2 tablet:grid-cols-2 
        desktopL:gap-8 desktopM:gap-6 tablet:gap-0 gap-4"
      >
        {ContentList.map((i, idx: number) => (
          <CardContent
            className="transition duration-300 ease-in-out hover:scale-[1.03] scale-100"
            key={idx}
            img={i?.img}
            title={i?.title}
            desc={i?.desc}
          />
        ))}
      </div>
      {/* -------------------------------------------- mobile ------------------------------------------- */}
      <SwiperCard
        className="hidden tablet:flex"
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          464: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        ElementList={ContentList.map((i, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <div className="flex justify-center mb-10 mx-2 tabletM:mx-[0.4rem]">
                <CardContent img={i?.img} title={i?.title} desc={i?.desc} />
              </div>
            </SwiperSlide>
          );
        })}
      />
    </div>
  );
};

export default SectionThree;

export const listTaps = [
  "Application Services",
  "Big Data & Analytics",
  "GIS",
  "Security",
  "Specific Solutions",
];

export const TapsTypeList = ({
  listType,
  onClick,
  activetap,
}: {
  listType: string[];
  onClick?: (tab: string) => void;
  activetap: string;
}) => {
  return listType?.length > 0 ? (
    <div className="relative my-12 mobile:my-5 overflow-auto scroll-element mx-5 mobile:mx-0">
      <div className="flex w-full">
        {listType?.map((tabName: string, idx: number) => {
          return (
            <div
              key={idx}
              className={`px-2 py-2 mx-2 text-center font-bold text-xl mobile:text-base cursor-pointer
               transition duration-300 ease-in-out ${
                 activetap === tabName ? "text-[#22242A]" : "text-[#7B7B7B]"
               } 
                whitespace-nowrap tab-text`}
              onClick={() => {
                onClick && onClick(tabName);
              }}
            >
              {tabName}
              <hr
                className={`bg-[#F79552] h-[0.17rem] mt-1 line-tab-text
                ${activetap === tabName ? "opacity-100" : "opacity-0"}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

const ContentList = [
  {
    img: cas1,
    title: `Full features & customize eRP / MRP`,
    desc: `Customize and Tailor-made Shopfloor system to meet individual requirements and solutions as well as interface to Global ERP and MRP systems such as SAP, Oracle and Microsoft Dynamic`,
  },
  {
    img: cas2,
    title: `Dashboard`,
    desc: `Plug in IIoT device for self-deploy onto existing machines then streaming Machine data & OEE analytics onto cloud / on-permise and visualized on dashboards realtime`,
  },
  {
    img: cas3,
    title: `Smart counting machine`,
    desc: `Build in machine by plug in vision camera with conveyor belt then add on AI deep learning and image processing as well as interface to ERP system in order to tracking output and cost`,
  },
];
