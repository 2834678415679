import { useNavigate } from "react-router-dom";

const CardOverlay = ({ img = "", title = "", desc = "", className = "" }) => {
  return (
    <div
      className={`relative shadow-md w-full h-full container-overlay ${className}`}
    >
      <img
        src={img}
        className="align-middle w-full max-h-[45rem] object-cover shadow-md"
        alt="card1"
      />
      <div className="absolute bottom-[2rem] left-[1rem] max-w-[20rem] p-4 bg-[rgba(0, 0, 0, 0.5)] ">
        <p className="text-2xl font-semibold text-white bg-text">{title}</p>
      </div>
      <div className="overlay">
        <div className="absolute bottom-[2rem] left-[1rem] p-4">
          <p className="text-2xl font-semibold text-white max-w-[20rem] pb-[1.5rem]">
            {title}
          </p>
          <p className="text-xl text-white">{desc}</p>
        </div>
      </div>
    </div>
  );
};

const CardContent = ({
  img = "",
  desc = "",
  title = "",
  className = "",
}: {
  img: string;
  desc: string;
  title: string;
  className?: string;
}) => {
  const navigate = useNavigate();
  // #C7DBEE
  // #F0E8E3
  // #F0F1F5
  return (
    <div
      className={`relative shadow-xl mobile:shadow-none desktopM:w-fit desktopL:w-fit h-full tablet:w-full w-[24rem] mobile:w-fit rounded-lg ${className}`}
    >
      <div
        className={`rounded-t-lg desktop:p-[1rem] tablet:p-3 p-2 ${"bg-[#C7DBEE]"}`}
      >
        <img
          src={img}
          className="align-middle rounded-t-lg w-full 
          desktopL:h-[20rem] h-[15rem] mobile:h-auto"
          alt="card1"
        />
      </div>
      <div
        className="desktopL:h-[27rem] desktopM:h-[20rem] rounded-b-lg flex flex-col justify-between bg-white 
      desktopL:px-[3rem] px-[1.8rem] mobile:px-0"
      >
        <p className="font-bold desktopL:text-2xl text-lg mobile:text-base pt-[2rem] mobile:pt-[1rem] h-[5rem] mobile:h-[5.5rem] mobile:line-clamp-3">
          {title}
        </p>
        <p
          className="font-semibold text-[#7B7B7B] desktopL:text-xl text-md pt-[1rem] desktopL:h-[12rem] desktopM:h-[12rem]
        h-[10rem] mobile:h-[4.5rem] mobile:line-clamp-3 mobile:pt-0"
        >
          {desc}
        </p>
        <p className="font-bold text-[#1DBEC8] desktopL:text-2xl text-lg desktopL:pt-[0rem] desktopL:pb-[4rem] 
        py-[2rem] text-center" onClick={()=>navigate("/contact-us")}>
          {"Request a quote"}
        </p>
      </div>
    </div>
  );
};

export { CardOverlay, CardContent };
