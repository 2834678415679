import { ReactNode } from "react";

export const PhoneCall = ({
  tel = "",
  children,
}: {
  tel: string;
  children: string | ReactNode;
}) => {
  return <a href={`tel:${tel}`}>{children}</a>;
};

export const Mailto = ({
  email = "",
  children,
}: {
  email: string;
  children: string | ReactNode;
}) => {
  return (
    <span
      className="cursor-pointer"
      onClick={() => {
        window.open(`mailto:${email}?subject=&body=`);
      }}
    >
      {children}
    </span>
  );
};
