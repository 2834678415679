import { baseUrl } from "./config";
import { api } from "./fetch";

export const postContactUs = async (data: any) => {
    try {
        const resp = await api.post(`${baseUrl.content}/contact-us`, data);
        return resp;
    } catch (error) {
        throw error;
    }
};