import SectionFirst from "./section/SectionFirst";
import SecondSection from "./section/SecondSection";
import SectionFour from "./section/SectionFour";
import SectionThree from "./section/SectionThree";

const Home = () => {
  return (
    <div>
      <SectionFirst />
      <SecondSection />
      <SectionThree />
      <SectionFour />
    </div>
  );
};

export default Home;

export const layoutStyle = `py-[5rem] mobile:py-[3rem] desktopL:px-[10vw] desktopM:px-[6rem] desktop:px-[6rem] 
tablet:px-[4rem] tabletM:px-[2rem] mobile:px-[2rem]`;
