import React, { useEffect, useRef, useState } from "react";
import "./customField.css";
import { thFlag } from "../../assets/img";

interface IDefaultInput {
  name: string;
  label: string;
  helperText?: string;
  onChange: (e: any) => void;
  // onChange: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
}

interface IInputProps extends IDefaultInput {
  type?: string;
  maxLength?: number;
  cols?: number;
  rows?: number;
}

// ---------------------------------------------CustomInput-------------------------------------------------------

export const CustomInput = ({
  name,
  label,
  helperText,
  onChange,
  onBlur,
  value,
  placeholder,
  type = "text",
  pattern,
  required = true,
  maxLength,
  ...rest
}: IInputProps) => {
  return (
    <div>
      <label htmlFor={name}>
        {label} {required && <span className="text-[red]">*</span>}
      </label>

      <input
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        placeholder={placeholder || ""}
        pattern={pattern}
        maxLength={maxLength}
        {...rest}
      />
    </div>
  );
};

// ---------------------------------------------CustomTextArea-------------------------------------------------------

export const CustomTextArea = ({
  name,
  label,
  helperText,
  onChange,
  onBlur,
  value,
  placeholder,
  pattern,
  required = true,
  maxLength,
  rows = 5,
  cols,
  ...rest
}: IInputProps) => {
  return (
    <div>
      <label htmlFor={name}>
        {label} {required && <span className="text-[red]">*</span>}
      </label>

      <textarea
        id={name}
        name={name}
        onChange={onChange}
        // value={value || ""}
        placeholder={placeholder || ""}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
        {...rest}
      />
    </div>
  );
};

// ---------------------------------------------CustomInputPhone-------------------------------------------------------

export const CustomInputPhone = ({
  name,
  label,
  helperText,
  onChange,
  onBlur,
  value,
  placeholder,
  type = "text",
  pattern,
  required = true,
  maxLength,
  ...rest
}: IInputProps) => {
  return (
    <div>
      <div>
        <label htmlFor={name}>
          {label} {required && <span className="text-[red]">*</span>}
        </label>
        {/* {helperText && <span className="helper-text">{helperText}</span>} */}
      </div>

      <div className="flex items-center">
        <div className="flex justify-center items-center bg-[#F9F9FB] py-2 px-3 border rounded-md mr-2">
          <img src={thFlag} alt={thFlag} className="w-[1.5rem] h-5" />
          <span className="ml-1">+66</span>
        </div>
        <input
          // required
          type={type}
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ""}
          placeholder={placeholder || ""}
          pattern={pattern}
          maxLength={maxLength}
          {...rest}
        />
      </div>
    </div>
  );
};

// ---------------------------------------CustomSelect----------------------------------------------------------

export const CustomSelect = ({
  placeHolder = "",
  label = "",
  name = "",
  required = true,
  options,
  isMulti = false,
  isSearchable = false,
  onChange,
  align,
}: {
  name?: string;
  label: string;
  placeHolder: string;
  options: any;
  isMulti?: boolean;
  required?: boolean;
  isSearchable?: boolean;
  onChange: any;
  align?: any;
}) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState<boolean>(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState<any>(isMulti ? [] : null); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState<string>(""); // Stores the value entered in the search input
  const searchRef: any = useRef(); // Reference to the search input element
  const inputRef: any = useRef(); // Reference to the custom select input element

  // Icon component
  const Icon = ({ isOpen }: { isOpen: boolean }) => {
    return (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#222"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={isOpen ? "translate" : ""}
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    );
  };

  // CloseIcon component
  const CloseIcon = () => {
    return (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    );
  };

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current && searchRef.current?.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e: { target: any }) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e: any) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option: any, index: number) => (
            <div key={`${option.value}-${index}`} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue.label;
  };

  const removeOption = (option: { value: any }) => {
    return selectedValue.filter((o: any) => o.value !== option.value);
  };

  const onTagRemove = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    option: any
  ) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: { value: any }) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o: any) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange({ name: name || newValue?.label, value: newValue?.value });
  };

  const isSelected = (option: { value: any }) => {
    if (isMulti) {
      return (
        selectedValue.filter((o: any) => o.value === option.value).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option: { label: string }) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="w-full">
      <div className="pb-1">
        {label} {required && <span className="text-[red]">*</span>}
      </div>
      <div className="custom--dropdown-container bg-white">
        <div
          ref={inputRef}
          onClick={handleInputClick}
          className="dropdown-input"
        >
          <div
            className={`dropdown-selected-value ${
              !selectedValue || selectedValue.length === 0 ? "placeholder" : ""
            }`}
          >
            {getDisplay()}
          </div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              <Icon isOpen={showMenu} />
            </div>
          </div>
        </div>

        {showMenu && (
          <div className={`w-full dropdown-menu alignment--${align || "auto"}`}>
            {isSearchable && (
              <div className="search-box">
                <input
                  id={name}
                  name={name}
                  className="form-control"
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                />
              </div>
            )}
            {getOptions().map((option: any) => (
              <div
                onClick={() => onItemClick(option)}
                key={option.value}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
