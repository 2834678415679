import { Chat, line, lineLogo } from "../assets/img";

const ButtonAddLine = ({
  text,
  onClick,
  className = "",
}: {
  text: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      className={`rounded-lg text-lg h-[3.5rem] w-[12rem] 
      border flex justify-center items-center font-bold text-[#00B900] cursor-pointer
      transition duration-700 ease-in-out bg-white ${className}`}
      onClick={onClick}
    >
      <img src={lineLogo} alt="line" className="w-6 h-6 mr-2" />
      {text}
    </div>
  );
};

const ButtontLine = ({
  text,
  onClick,
  className = "",
}: {
  text: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      className={`rounded-lg h-[3rem] w-[13rem]
      border flex justify-center items-center text-lg font-semibold cursor-pointer
      transition duration-700 ease-in-out bg-[#F4F8FB] ${className}`}
      onClick={onClick}
    >
      <img src={line} alt="line" className="w-7 h-7 mr-2" />
      {text}
    </div>
  );
};

const MainButton = ({
  text,
  onClick,
  className = "",
}: {
  text: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      className={`rounded-lg h-[3rem] w-[10rem]
      bg-gradient-to-b from-cyan-300 to-indigo-400
      transition duration-500 ease-in-out cursor-pointer
      hover:from-cyan-400 hover:to-indigo-500 font-semibold
     flex justify-center items-center text-white text-lg ${className}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

const ChatButton = ({
  text,
  onClick,
  className = "",
}: {
  text: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div
      className={`rounded-t-lg h-[3.5rem] w-[12rem] 
      mobile:w-[3.5rem] mobile:h-[3.3rem] mobile:rounded-2xl 
      tablet:w-[3.5rem] tablet:h-[3.3rem] tablet:rounded-2xl 
      flex justify-center items-center text-white text-xl
      transition duration-500 ease-in-out cursor-pointer
      bg-gradient-to-b from-[#F68C1E] to-[#E3610A] ${className}`}
      onClick={onClick}
    >
      <img src={Chat} alt="line" className="w-7 h-7 mr-2 tablet:mr-0 mobile:mr-0" />
      <span className="tablet:hidden mobile:hidden">{text}</span>
    </div>
  );
};

export { MainButton, ButtontLine, ChatButton, ButtonAddLine };
