import { gotechLogoWhite } from "../assets/img";
import { layoutStyle } from "../views/Home";

const Footer = () => {
  return (
    <div className={`${layoutStyle} bg-gradient-to-b from-[#17233F] to-[#091022] border-t border-t-slate-500`}>
      <img src={gotechLogoWhite} alt="logo-gotech" className="w-[7rem] h-auto" />
      <div className={`flex tabletM:grid mobile:grid tabletM:grid-cols-1 mobile:grid-cols-1 justify-between items-center mt-[1.5rem]`}>
          <p className="text-white text-base tabletM:text-sm mobile:text-sm">
            18/5 Niran Avanue, Ramkhamhang
            <p className="mt-[0.5rem]"> 174, Minburi, Bangkok 10510</p>
          </p>
        <div className="text-white tabletM:mt-[1.5rem] mobile:mt-[1.5rem]">
          <p className="text-base tabletM:text-sm mobile:text-sm">
            Term of Use | Privacy and Cookies Statement | Cookie consent
          </p>
          <p className="text-sm tabletM:text-xs mobile:text-xs mt-[0.5rem]">
            Copyright © 2023 The Gethergo CO.,Ltd. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );

  // old
  // return (
  //   <div className={`grid grid-cols-3 mobile:grid-cols-1 gap-4 ${layoutStyle}`}>
  //     <div className="flex justify-center mobile:justify-start">
  //       <div>
  //         <img src={gotechLogo} alt="logo-gotech" className="w-[7rem] h-auto" />
  //         <p className="text-[#666666] text-base my-[2rem]">
  //           18/5 Niran Avanue, Ramkhamhang
  //           <br /> 174, Minburi, Bangkok 10510
  //         </p>
  //         <p className="text-[#404040] desktopL:text-3xl text-xl font-bold mb-1">
  //           <PhoneCall tel={CONTACTINFO?.PHONE}>{CONTACTINFO?.PHONE}</PhoneCall>
  //         </p>
  //         <p className="text-[#666666] text-lg font-bold mobile:font-medium">
  //           <Mailto email={CONTACTINFO?.EMAIL}>{CONTACTINFO?.EMAIL}</Mailto>{" "}
  //         </p>
  //       </div>
  //     </div>
  //     {/* ----------------------------------------------------------------------- */}
  //     <div className="flex justify-center mobile:justify-start mobile:my-[1rem] my-0">
  //       <div>
  //         <p className="text-[#404040] text-xl font-bold ">Our Services</p>
  //         <p className="text-[#666666] text-base my-[0.5rem] leading-7">
  //           Enterprise / ERP / CRM Software Development Mobile and Website
  //           Application Development Hardware and Embedded Development
  //           Implementation and Optimization System Technology Consulting
  //         </p>
  //       </div>
  //     </div>
  //     {/* ----------------------------------------------------------------------- */}
  //     <div className="flex justify-center mobile:justify-start">
  //       <div>
  //         <p className="text-[#404040] text-xl font-bold ">Company</p>
  //         <p className="text-[#666666] text-base my-[0.5rem] leading-7">
  //           <a
  //             href="/#/contact-us"
  //             onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  //           >
  //             Contact Us
  //           </a>
  //           {/* About Us
  //           <br /> Service */}
  //           {/* <br /> Contact Us */}
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Footer;
