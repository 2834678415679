import { useState } from "react";
import {
  BannerItem,
  BannerItemMB,
  Dot,
  cubeVecter,
  growIcon,
} from "../../assets/img";
import { ButtonAddLine } from "../../components/Buttont";
import Navbar from "../../layout/Navbar";
import { layoutStyle } from "../Home";

const SectionFirst = () => {
  const [isSticky, setIsSticky] = useState(false);

  return (
    <div
      className="bg-gradient-to-b from-[#17233F] to-[#091022] relative"
      id="Overview"
    >
      <Navbar onSticky={(isStick: boolean) => setIsSticky(isStick)} />
      <div
        className={`flex mobile:flex-col desktop:justify-center ${layoutStyle} 
         desktopM:pb-[7rem] tabletM:pb-[7rem] mobile:pb-[4rem] ${
          isSticky
            ? "tablet:pt-[9.7rem] mobile:pt-[9.7rem] tabletL:pb-[4.5rem] "
            : "tablet:pt-[6rem] mobile:pt-[6rem] tabletL:pb-[4rem] "
        } w-full`}
      >
        <img src={growIcon} alt="dot" className="absolute top-0 left-0 z-[1]" />
        <img
          src={cubeVecter}
          alt="cubeVecter"
          className="absolute desktopL:bottom-[-14%] desktopL:left-[10%] desktopM:bottom-[-15%] desktopM:left-[9%] left-[5%]
          tablet:bottom-[-12%] mobile:bottom-[2%] z-[1] w-[auto] mobile:w-[12rem] tablet:w-[12rem] mobile:hidden"
        />
        {/* ------------------------------------------------------------------------ */}
        <div
          className="text-white z-[3] relative desktopL:w-[50vw] desktopM:w-[60vw]
        desktop:pb-0 pb-[10rem] mobile:pb-0 pt-0"
        >
          <div>
            <img src={Dot} alt="dot" className="mb-2 mobile:mb-3" />
            <div className="font-semibold break-words text-5xl mobile:text-3xl leading-[5rem] mobile:leading-[3rem]">
              Enabling <span className="text-[#34bdc6]">your success</span>
              <br />
              through technology
            </div>
            <div className="py-[2rem] mobile:py-[1rem] text-xl mobile:text-lg">
              Set the trends for desktop & server virtualization technology
            </div>
          </div>
          <ButtonAddLine
            text={"Add Line"}
            className="mobile:!h-[3rem] mobile:mt-[1rem] mobile:text-lg mobile:w-fit mobile:px-[1.5rem]"
            onClick={() => window.open("http://line.me/ti/p/~@gethergo")}
          />
        </div>
        {/* ------------------------------------------------------------------------ */}
        <div
          className="z-[2] 
        desktop:relative  desktop:w-full desktop:right-0 desktop:top-0
        tabletL:absolute tabletL:w-[40rem] tabletL:right-2 tabletL:top-[13.5rem]
        tabletM:absolute tabletM:w-[38rem] tabletM:right-1 tabletM:top-[23rem]
        mobile:relative mobile:w-max mobile:left-[-28vw] mobile:top-[1rem]"
        >
          {/* origin mobile:w-[119vw] mobile:h-auto */}
          <img
            src={BannerItem}
            alt="vecter-banner"
            className="mobile:hidden block"
          />
          <img
            src={BannerItemMB}
            alt="vecter-banner"
            className="mobile:block hidden mobile:w-[119vw] mobile:h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionFirst;
