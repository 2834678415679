import { SwiperSlide } from "swiper/react";
import {
  Card1,
  solution2,
  solution3,
  solution4,
  solution5,
  solution7,
} from "../../assets/img";
import SwiperCard from "../../components/SwiperCard";
import { layoutStyle } from "../Home";
import { CardOverlay } from "../../components/CardOverlay";

const SecondSection = () => {
  return (
    <div className={`${layoutStyle}`} id="Solutions & Services">
      <div className="relative">
        {/* <div className="flex justify-end mb-0 mobile:absolute mobile:right-[-1.5rem] mobile:top-4">
          <ChatButton
            text={"Let’s chat"}
            className="mobile:h-[3rem]"
            onClick={() => {}}
          />
        </div> */}
        <div className="flex flex-col justify-center items-center mb-[5rem] mt-[5rem] mobile:mb-[2rem]">
          <p className="text-[#1DBEC8] text-3xl mobile:text-lg font-bold">
            What we do
          </p>
          <p className="text-[#22242A] text-5xl tablet:text-4xl mobile:text-xl font-bold mt-[1.5rem] mobile:mt-[0.5rem]">
            Our solutions & services
          </p>
        </div>
      </div>
      {/* -------------------------------------------- desktop ------------------------------------------- */}
      <div className="grid tablet:hidden grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
        {ContectList.map((i, idx: number) => (
          <CardOverlay key={idx} img={i?.img} title={i?.title} desc={i?.desc} />
        ))}
      </div>
      {/* -------------------------------------------- mobile ------------------------------------------- */}
      <SwiperCard
        className="desktop:hidden tablet:flex mobile:hidden"
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          464: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        ElementList={ContectList.map((i, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <div className="flex justify-center mb-2 mx-2 tabletM:mx-[0.4rem]">
                <CardOverlay
                  key={idx}
                  img={i?.img}
                  title={i?.title}
                  desc={i?.desc}
                />
              </div>
            </SwiperSlide>
          );
        })}
      />
    </div>
  );
};

export default SecondSection;

const ContectList = [
  {
    img: Card1,
    title: "Enterprise / ERP / CRM Software Development",
    desc: `
To enhance the management and work flow of your company resource, alminate the repetitive tasks and increasing work performance.`,
  },
  {
    img: solution2,
    title: "Mobile and Website Application Development",
    desc: `We are specialize with over 30 programming languages to develop any Windows and Mac applications, for your business.`,
  },
  {
    img: solution3,
    title: "Hardware and Embedded Development",
    desc: `Offer new ideas, innovate new products, create architectural intelligence in order to make you reach your business goal.`,
  },
  {
    img: solution4,
    title: "Implementation and Optimization System",
    desc: "Optimize your work result, get rid of work redundancy and increase system performance to make a better way of work.",
  },
  {
    img: solution5,
    title: "Technology Consulting",
    desc: "Eliminate the problem in the future by the perfect & powerful system architecture design that meet your required and match with  computer fields.",
  },
  {
    img: solution7,
    title: "Smart Factory",
    desc: "Build in machine by plug in vision camera with conveyor belt then add on AI deep learning and image processing as well as interface to ERP system in order to tracking output and cost",
  },
];
