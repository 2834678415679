export const api = {
    async post(url = "", data = {}) {
    
        let headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": process.env.NEXT_PUBLIC_GATEWAY_KEY as string,
        };
    
        // if (auth.currentUser) {
        //   const token = `eyJhbGciOiJSUzI1NiIsImtpZCI6ImYyOThjZDA3NTlkOGNmN2JjZTZhZWNhODExNmU4ZjYzMDlhNDQwMjAiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVGhhbmF0IEp1bW5lYW5idW4iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTHpJRkI3ekVROUZwZGhaV05IdkIwMzhJX3NfNlU4WWdwZlIxcjktb0hmPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2dldGhlcmdvIiwiYXVkIjoiZ2V0aGVyZ28iLCJhdXRoX3RpbWUiOjE3MTE5NTQ4OTMsInVzZXJfaWQiOiJwU0JlMnVubzZpZVJOT3U0TlJnZHRTcmJkbTczIiwic3ViIjoicFNCZTJ1bm82aWVSTk91NE5SZ2R0U3JiZG03MyIsImlhdCI6MTcxMzM0MzkzNSwiZXhwIjoxNzEzMzQ3NTM1LCJlbWFpbCI6InRoYW5hdC50ZWF5OUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwNzA0ODQyMTM0OTEzMjI4NDMxOSJdLCJlbWFpbCI6WyJ0aGFuYXQudGVheTlAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.BmBwGe6D-9aJWX60hTfzR8coCltWdvFSWumje8wvleaT9tOk1gByWLjpEIcOst9as-OpHTb7vaYDwxXOkydb_1-Gplt4rgZmfw_ZG_Sb8J1bpkGNFzAZpdJmFLFIF9-baGbTJ-xPLi-LSKTXrz8HjJ-0eF8BeS-1mJyuldiJmrSnaf3QFHb5C1FFyJLwH5xGcOfItYF
        //   `;
        //   // const token = await auth.currentUser.getIdToken();
        //   headers["Authorization"] = `Bearer ${token}`;
        // }
    
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: headers,
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data),
        });
        // console.log("response -> ", response);
    
        if (response.ok) {
          return response.json();
        } else {
          // eslint-disable-next-line no-throw-literal
          throw "apiGet error";
        }
      },
}