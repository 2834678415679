import { HashRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./views/Home";
import ContactUs from "./views/ContactUs";
import "./assets/css/variable.css";
import "swiper/css";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
